
import { defineComponent, ref, reactive, toRefs, toRaw, computed, onMounted } from 'vue'
import uploadImage from '@/views/courseManger/componets/upload-image.vue'
import uploadVideo from '@/views/exerciseManager/components/upload-video.vue'
import { SEND_TYPE, OSSURL } from '@/hooks/ali-oss'
import { PictureOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { exerciseTag, createExe, fetchExerciseDetail, putExercise } from '@/api/index'
import { EXERISE, EXERISE_TAG } from '@/hooks/config'
import { useRoute, useRouter } from 'vue-router'
import { ValidateErrorEntity, RuleObject } from 'ant-design-vue/es/form/interface'
import previewDialog from '@/views/examManager/components/previewDialog.vue'
let routerName: any = ''
const columns = [
  {
    title: '选项',
    dataIndex: 'key',
    key: 'key',
    align: 'center',
    width: 80
  },
  {
    title: '选项文字',
    dataIndex: 'content',
    key: 'content',
    slots: { customRender: 'content' }
  },
  {
    title: '图片',
    dataIndex: 'img',
    key: 'img',
    slots: { customRender: 'img' }
  },
  {
    title: '是否正确',
    dataIndex: 'isCorrect',
    key: 'isCorrect',
    slots: { customRender: 'correct' },
    align: 'center'
  }
]
export interface FileProps {
  file?: object;
  file_name?: string;
  file_path?: string;
  file_size?: string;
  file_type?: string;
  url?: string;
  [key: string]: any;
}
interface FormState {
  type: number | any;
  tag_id: number;
  remarks: string;
  text: string;
  image: string | undefined;
  video: string | undefined;
  answers: any;
}
export default defineComponent({
  name: 'createMatching',
  props: ['id'],
  components: {
    uploadImage,
    previewDialog
    // PictureOutlined
    // uploadVideo
  },
  setup (props) {
    const formRef = ref()
    const route = useRoute()
    const router = useRouter()
    const typeList = ref(EXERISE)
    const tagList = ref()
    const formState: FormState = reactive({
      type: typeList.value.find(item => item.router === route.name)?.id,
      tag_id: 1,
      remarks: '',
      text: '',
      image: '',
      video: '',
      answers: {
        item1: [{
          list: [],
          text: '',
          image: '',
          video: '',
          events: null
        }, {
          list: [],
          text: '',
          image: '',
          video: '',
          events: null
        }, {
          list: [],
          text: '',
          image: '',
          video: '',
          events: null
        }, {
          list: [],
          text: '',
          image: '',
          video: '',
          events: null
        }],
        item2: [{
          list: [],
          text: '',
          image: '',
          video: '',
          events: null
        }, {
          list: [],
          text: '',
          image: '',
          video: '',
          events: null
        }, {
          list: [],
          text: '',
          image: '',
          video: '',
          events: null
        }, {
          list: [],
          text: '',
          image: '',
          video: '',
          events: null
        }]
      }
    })
    const getExamTag = () => {
      exerciseTag({}).then(res => {
        tagList.value = res.data
        formState.tag_id = tagList.value[0].id
      })
    }
    const checkList = (value: any) => {
      const arr = value.map((x: any) => (x.text.trim() || x.image).length > 0 ? 1 : 0)
      const _lastLen = arr.lastIndexOf(1) + 1
      const _allLen = value.filter((item: any) => item.text.trim().length || item.image.length).length
      return {
        _lastLen,
        _allLen
      }
    }
    // 检验选项
    const checkAnswers = async (rule: RuleObject, value: any) => {
      const _isLeft = checkList(value.item1)
      const _isRight = checkList(value.item2)
      if (!(_isLeft._lastLen === _isLeft._allLen && _isLeft._allLen >= 2)) {
        return Promise.reject(new Error('左侧格式不正确，请检查'))
      }
      if (!(_isRight._lastLen === _isRight._allLen && _isRight._allLen >= 2)) {
        return Promise.reject(new Error('右侧格式不正确，请检查'))
      }
      if (_isLeft._lastLen !== _isRight._lastLen) {
        return Promise.reject(new Error('左右两侧格式不正确，请检查'))
      }
      return Promise.resolve()
    }
    // 表单验证
    const rules = {
      tag_id: [{ required: true, message: '请选择习题标签', type: 'number' }],
      text: [{ required: true, message: '输入题干', whitespace: true }],
      answers: [{ required: true, validator: checkAnswers, trigger: 'blur', whitespace: true }]
    }
    const upload1 = ref()
    const upload2 = ref()
    const listIcon: FileProps = ref([])
    const listImageTarget: FileProps = ref([])
    const upObj = ref(SEND_TYPE)
    const videoUpObj = ref([SEND_TYPE[3]])
    const changeFileIcon = (lists: []) => {
      listIcon.value = lists
    }
    const changeFileImageTarget = (lists: []) => {
      listImageTarget.value = lists
    }
    const resetListIcon = () => {
      listIcon.value.pop()
    }
    const resetImageTarget = () => {
      listImageTarget.value.pop()
    }
    const iconUrl = computed(() => {
      if (listIcon.value.length === 0) {
        return ''
      } else {
        return (listIcon.value[0] as FileProps).file_path
      }
    })
    const videoUrl = computed(() => {
      if (listImageTarget.value.length === 0) {
        return ''
      } else {
        return (listImageTarget.value[0] as FileProps).file_path
      }
    })
    const changeTableFile = (lists: [], record: any) => {
      if (lists.length > 0) {
        record.list = lists
        record.image = record.list[0].file_path
      }
    }
    // 删除表格里面的图片
    const deleteTableFile = (record: any) => {
      record.list.pop()
      record.image = ''
    }
    // 预览弹出层
    const previewVisible = ref(false)
    const previewId = ref(0)
    const openPreview = (id: number) => {
      previewId.value = id
      previewVisible.value = true
    }
    // 创建
    const createExerciseHttp = (data: any) => {
      createExe(data).then(res => {
        openPreview(res.data)
        // message.success('创建成功')
        // router.push({ name: 'ExerciseManager' })
      })
    }
    // 编辑
    const updataExercise = (data: any) => {
      putExercise(Object.assign(data, { id: props.id })).then(res => {
        openPreview(props.id)
        // message.success('编辑成功')
        // router.back()
        // router.push({ name: 'ExerciseManager' })
      })
    }
    // 重置参数
    const createAndUpdate = () => {
      const _question = {
        text: formState.text,
        image: iconUrl.value,
        audio: '',
        video: videoUrl.value,
        events: null
      }
      const _leftTtem: any[] = []
      formState.answers.item1.filter((item: any) => {
        if (item.text || item.image) {
          _leftTtem.push({
            text: item.text,
            image: item.image,
            video: '',
            audio: '',
            events: null
          })
        }
      })
      const _rightTtem: any[] = []
      formState.answers.item2.filter((item: any) => {
        if (item.text || item.image) {
          _rightTtem.push({
            text: item.text,
            image: item.image,
            video: '',
            audio: '',
            events: null
          })
        }
      })
      const _content = JSON.stringify({
        question: _question,
        answers: {
          item1: _leftTtem,
          item2: _rightTtem
        }
      })
      const _data: any = {
        type: formState.type,
        tag_id: formState.tag_id,
        content: _content,
        remarks: formState.remarks
      }
      if (props.id) { // 编辑
        updataExercise(_data)
      } else { // 创建
        console.log(formState)
        createExerciseHttp(_data)
      }
    }
    const saveForm = () => {
      formRef.value.validate().then(() => {
        createAndUpdate()
      }).catch((error: ValidateErrorEntity<FormState>) => {
        console.log('error', error)
      })
    }
    // 获取详情
    const getDetail = () => {
      fetchExerciseDetail({ id: props.id }).then(res => {
        console.log(res)
        const _content = JSON.parse(res.data.content)
        listIcon.value = _content.question.image ? [{
          url: OSSURL + '/' + _content.question.image,
          file_type: 'p1',
          file_path: _content.question.image
        }] : []
        listImageTarget.value = _content.question.video ? [{
          url: OSSURL + '/' + _content.question.video,
          file_type: 'm4',
          file_path: _content.question.video
        }] : []
        formState.tag_id = res.data.tag_id
        formState.remarks = res.data.remarks
        formState.text = _content.question.text
        // formState.answers.item1.map((item: any) => {

        // })
        _content.answers.item1.map((item: any, index: any) => {
          if (item.image.length) {
            item.list = [{
              url: `${OSSURL}/${item.image}`,
              file_type: 'p1',
              file_path: item.image
            }]
          } else {
            item.list = []
          }
          formState.answers.item1[index] = item
          // return item
        })
        _content.answers.item2.map((item: any, index: any) => {
          if (item.image.length) {
            item.list = [{
              url: `${OSSURL}/${item.image}`,
              file_type: 'p1',
              file_path: item.image
            }]
          } else {
            item.list = []
          }
          formState.answers.item2[index] = item
          // return item
        })
      })
    }
    const handelCancel = () => {
      router.replace({ name: routerName.name, params: { id: routerName.params.id } })
    }
    onMounted(() => {
      getExamTag()
      if (props.id) {
        getDetail()
      }
    })
    return {
      previewVisible,
      previewId,
      formRef,
      rules,
      tagList,
      typeList,
      formState,
      columns,
      ...toRefs(formState),
      upload1,
      upload2,
      listIcon,
      listImageTarget,
      upObj,
      changeFileIcon,
      changeFileImageTarget,
      resetListIcon,
      resetImageTarget,
      videoUpObj,
      saveForm,
      changeTableFile,
      deleteTableFile,
      handelCancel
    }
  },
  beforeRouteEnter (to, from, next) {
    routerName = from
    next()
  }
})
